<template>
    <v-container class="col-12 col-lg-8 col-xl-8 mx-auto pt-8">
        <v-card class="glass--bg">
            <v-card-title>Indstillinger</v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Temavælger</v-list-item-title>
                            <span class="caption font-italic grey--text">Lys / mørk tema</span>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch
                                :v-model="$vuetify.theme.dark"
                                @click="$vuetify.theme.dark = !$vuetify.theme.dark"
                            />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Kontaktmail</v-list-item-title>
                            <span class="caption font-italic grey--text">Din login e-mail vil forblive den samme</span>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-text-field
                                :v-model="$store.state.auth.user.contactMail"
                            />
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        name: "Settings"
    }
</script>

<style scoped>

</style>